<template>
  <div class="home">
    <form class="form-horizontal" onsubmit="return false;">
      <div class="form-group">
        <label class="control-label col-sm-2" for="inputData">데이터</label>
        <div class="col-sm-10">
          <textarea class="form-control" id="inputData" rows="3">
테스트용 서명 데이터 입니다.</textarea
          >
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-2" for="ssn">신원확인 정보</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="ssn" placeholder="인증서 신원확인 정보" />
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-2" for="inputData"></label>
        <div class="col-sm-10">
          <button @click="signDataTest">데이터 서명</button>
        </div>
      </div>
    </form>
    <br /><br /><br /><br />
    <div class="panel panel-success">
      <div class="panel-heading">서명결과</div>
      <div class="panel-body">
        <form onsubmit="return false;" class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-2 control-label">서명결과</label>
            <div class="col-sm-10">
              <textarea class="form-control" id="signedData" rows="5"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">인증서 정보</label>
            <div class="col-sm-10">
              <div id="signCertInfo" class="scrollable-data"></div>
            </div>
          </div>
        </form>
        <div>
          <form class="form-inline" onsubmit="return false;">
            <button @click="verifySignedDataTest">서명 데이터 검증.</button>
            <div class="checkbox">
              <label> <input type="checkbox" id="certValidation" />결과에 인증서 검증 포함 </label>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="panel panel-success">
      <div class="panel-heading">서명 데이터 검증결과.</div>
      <div class="panel-body">
        서명 원문
        <textarea id="verifyResult" rows="3" style="width: 100%"></textarea>
        서명 정보
        <div id="verifySignerInfo" class="scrollable-data"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ApiService from '@/services/ApiService';

import { COMMON_CODE_ACTION } from '@/store/modules/common/action';

export default {
  name: 'Home',
  components: {
    //HelloWorld,
  },
  async created() {
    const { GET_BANK_CODE } = COMMON_CODE_ACTION;
    this.$store.dispatch(GET_BANK_CODE);
    const projectCode = await ApiService.shared.getData(this.$apiPath.PROJECT_CODE);
    console.log('projectCode :>> ', projectCode);

    // const bankCode = await ApiService.shared.getData('/bankcode');
    // console.log('bankCode :>> ', bankCode);
  },
  mounted() {
    // setTimeout(() => {
    // nxTSPKI.onInit(function () {
    //   //nxTSPKI.init 함수 완료 후 실행해야 하는 함수나 기능 작성 위치
    //   console.log('nxTSPKI.onInit');
    // });
    //nxTSPKI.init(true);
  },
  computed: {
    ...mapState({
      bankCodeList: (state) => state.commonCode.bankCodeList,
    }),
  },
  methods: {
    signDataTest() {
      let options = {};
      $('#signedData').val('');
      $('#signCertInfo').empty();
      let data = $('#inputData').val();
      let ssn = $('#ssn').val();
      if (ssn != '') {
        options.ssn = ssn;
      }
      nxTSPKI.signData(data, options, this.sign_complete_callback);
    },
    objectToTable(caption, data) {
      caption = caption || '';
      var table =
        '<table class="custom table table-striped"><captoin>' +
        caption +
        '</captoin><col width=30% ></col><col width=70%></col>';
      for (var prop in data) {
        table += '<tr><td>';
        table += prop;
        table += '</td><td>';
        if (typeof data[prop] == 'object') {
          table += this.objectToTable('', data[prop]);
        } else {
          table += data[prop];
        }
        table += '</td></tr>';
      }
      table += '</table>';
      return table;
    },
    verifySignedDataTest() {
      var data = $('#signedData').val();
      $('#verifyResult').val('');
      $('#verifySignerInfo').html('');
      var certValidation = $('#certValidation').is(':checked');
      nxTSPKI.verifySignedData(data, { certValidation: certValidation }, verify_complete_callback);
    },
    sign_complete_callback(res) {
      if (res.code == 0) {
        const signedData = res.data.signedData;
        this.sendSignedData(signedData);
        $('#signedData').val(res.data.signedData);
        $('#signCertInfo').append(this.objectToTable('', res.data.certInfo));
      } else {
        alert('error code = ' + res.code + ', message = ' + res.errorMessage);
      }
    },
    sendSignedData(data) {
      const path = 'http://117.52.83.150/api/ligs/cmmn/signeVeryfy';
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      var raw = data;
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      fetch(path, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log('=========');
          console.log(result);
          console.log('//=========');
        })
        .catch((error) => console.log('error', error));
    },
  },
};
</script>
